import GlobalContext from "../Contexts/GlobalContext";
import Image1 from "../assets/1.jpg"
import Image2 from "../assets/2.jpg"
import Image3 from "../assets/3.jpg"
import Image4 from "../assets/4.jpg"
import Rating from "../assets/stars.png"

export const GlobalState = ({ children }) => {

    const data = [
        {
            id: 1,
            image: Image1
        },
        {
            id: 2,
            image: Image2
        
        },
        {
            id: 3,
            image: Image3
        },
        {
            id: 4,
            image: Image4
        }
    ]

    const reviews = [
        {
            id: "1",
            name: "Felipe L.",
            comment: "Muito atencioso e profissional, atendimento top e de qualidade. Vou voltar com certeza!",
            image: Rating
        },
        {
            id: "2",
            name: "Martim G.",
            comment: "Excelente quiropraxista! Recomendo!!!",
            image: Rating
        },
        {
            id: "3",
            name: "Leda R.",
            comment: "Quiropraxia é maravilhoso, resolveu minhas dores de coluna, eu recomendo!",
            image: Rating
        },
        {
            id: "4",
            name: "Nicolas C.",
            comment: "Excelente! Aliviou muito minha dor ciática. Recomendo muito o Dr Guilherme",
            image: Rating
        },
        {
            id: "5",
            name: "Anderson H.",
            comment: "O trampo mais top das galáxias parabéns Gui tu é o melhor irmão!",
            image: Rating
        },
        {
            id: "6",
            name: "Noeli S.",
            comment: "O Guilherme é muito atencioso e um excelente profissional, recomendo!",
            image: Rating
        },
        {
            id: "7",
            name: "Lidia K.",
            comment: "Atendimento maravilhoso, minha dor passou. Super recomendo este profissional 👏👏",
            image: Rating
        },
        {
            id: "8",
            name: "Eloá C.",
            comment: "Foi uma experiência muito boa, parabéns!",
            image: Rating
        },
        {
            id: "9",
            name: "Cristina B.",
            comment: "Super indico, atendimento maravilhoso.",
            image: Rating
        },
        {
            id: "10",
            name: "Rute S.",
            comment: "Dr Guilherme ótimo profissional, transmite confiança ao paciente.",
            image: Rating
        },
        {
            id: "11",
            name: "Julia S.",
            comment: "Excelente atendimento!",
            image: Rating
        },
        {
            id: "12",
            name: "Gabriel S.",
            comment: "Ótimo profissional!",
            image: Rating
        },
        {
            id: "13",
            name: "Thaís B.",
            comment: "Doutor Guilherme, é um excelente profissional e que proporciona consultas humanizadas para os seus pacientes. Ele passa muita segurança e tranquilidade em seus atendimentos.",
            image: Rating
        },
        {
            id: "14",
            name: "Clarice H.",
            comment: "Ótimo atendimento. Recomendo.",
            image: Rating
        },
        {
            id: "15",
            name: "Amanda P.",
            comment: "Ótimo profissional, recomendo a todos!",
            image: Rating
        },
        {
            id: "16",
            name: "Gabriela A.",
            comment: "Excelente profissional",
            image: Rating
        },
        {
            id: "17",
            name: "André C.",
            comment: "Excelente profissional, recomendo!!!",
            image: Rating
        },
        {
            id: "18",
            name: "Bettania M.",
            comment: "Gostei muito estou me sentido muito melhor, a dor no braço melhorou bastante. Continuo fazendo o que ele recomendou.",
            image: Rating
        },
        {
            id: "19",
            name: "Jonas M.",
            comment: "Excelente profissional! Desde que comecei a consultar com o Guilherme minha qualidade de vida melhorou bastante e a dos meus pais também :)",
            image: Rating
        },
        {
            id: "20",
            name: "Dener C.",
            comment: "Excelente profissional, trabalhando com respeito e disciplina!",
            image: Rating
        },
        {
            id: "21",
            name: "Silvia K.",
            comment: "Excelente profissional,Maravilhoso. Cheguei com bastante dor e na primeira consulta já tive ótimos resultados,super atencioso e mãos de anjo. Obrigada Guilherme.",
            image: Rating
        },
        {
            id: "22",
            name: "Letícia Z.",
            comment: "Excelente profissional, recomendo!",
            image: Rating
        },
        {
            id: "23",
            name: "Matheus B.",
            comment: "Tinha um problema muito sério na coluna e com algumas sessões, já vejo a grande diferença!",
            image: Rating
        }
    ]
   
    const globalData = {
        data,
        reviews,
      };

  return (
    <GlobalContext.Provider value={globalData}>{children}</GlobalContext.Provider>
  );
};
